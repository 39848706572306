import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    MenuIcon,
    XIcon,
    HomeIcon,
    InformationCircleIcon,
    PhoneIcon,
} from '@heroicons/react/outline'

const solutions = [
    {
        name: 'Home',
        description: 'Get a better understanding of where your traffic is coming from.',
        href: '/',
        icon: HomeIcon,
    },
    {
        name: 'About',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: '/about',
        icon: InformationCircleIcon,
    },
    {
        name: 'Contact',
        description: "Connect with third-party tools that you're already using.",
        href: '/contact',
        icon: PhoneIcon,
    },
]


export default function Navbar() {
    return (
        <Popover className="relative bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                <span className="sr-only">Workflow</span>
                <h1 className="font-bold text-2xl">Joko Purnomo</h1>
                </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
            </div>
            
            <div className="hidden md:flex space-x-10 items-center justify-end md:flex-1 lg:w-0">
                <a href="/" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    Home
                </a>
                <a href="/about" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    About
                </a>
                <a href="/contact" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    Contact
                </a>
            </div>
            </div>
        </div>

        <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                    <div>
                    <h1 className="font-bold text-2xl">Joko Purnomo</h1>
                    </div>
                    <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                    </div>
                </div>
                <div className="mt-6">
                    <nav className="grid gap-y-8">
                    {solutions.map((item) => (
                        <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        >
                        <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                        </a>
                    ))}
                    </nav>
                </div>
                </div>  
            </div>
            </Popover.Panel>
        </Transition>
        </Popover>
        
    )
}