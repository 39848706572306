import React from 'react'
import {
    FaGithub,
    FaTelegram,
    FaInstagram,
} from "react-icons/fa";
import {
    AiOutlineMail 
} from "react-icons/ai";

export default function Footer() {
    return (
        <div>
            <div className="relative bg-white">
                <div className="max-w-4xl mx-auto px-4 sm:px-6">
                    <div className="sm:flex sm:-mx-4 py-6 sm:mt-12 sm:pt-12 border-t">
                        <div className="w-full flex justify-center md:justify-start">
                            <strong>Built with GatsbyJS &copy; 2021</strong>
                        </div>
                        <div className="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
                            <div className="flex justify-center content-center items-center xl:justify-end">
                                <a href="mailto:jokoganss07@gmail.com" className="w-8 h-8 border border-gray-400 rounded-full text-center py-1 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600">
                                    <AiOutlineMail className="w-full h-full" />
                                </a>
                                <a href="https://github.com/jokopurnomo07" className="w-8 h-8 border border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-blue-400 hover:border-blue-400">
                                    <FaGithub className="w-full h-full" />
                                </a>
                                <a href="@rokiadhytama" className="w-8 h-8 border border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-red-600 hover:border-red-600">
                                    <FaTelegram className="w-full h-full" />
                                </a>
                                <a href="https://www.instagram.com/rokiadhytama/" className="w-8 h-8 border border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-red-600 hover:border-red-600">
                                    <FaInstagram className="w-full h-full" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
